import React, { useState } from "react";
import styled from "styled-components";
import { CoorporateScheme } from "client-v2";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../../redux";
import { bindActionCreators } from "redux";

import { Text, Color, Button, Filter } from "@adoptaunabuelo/react-components";
import { Plus } from "lucide-react";
import Card from "../../../components/Containers/Card";
import CardList from "../../../components/Profile/CardList";
import LoadVolunteerModal from "../../../components/Modals/LoadVolunteersModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const SectionVolunteer = (props: Props) => {
	const dispatch = useDispatch();
	const { setCoorporateProfileFunction } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const [showAddModal, setShowAddModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState<{id: string}[]>([]);

	const onFilterChange = (
		ops: {
			id: string;
			[key: string]: any;
		}[]
	) => {
        setSelectedOption(ops);
		const incidence = ops.findIndex((i) => i.id === "incidence");
		const noIncidence = ops.findIndex((i) => i.id === "noIncidence");
		setCoorporateProfileFunction({
			launch: true,
			function: "volunteer",
			data: {
				state:
					incidence > -1
						? "incidence"
						: noIncidence > -1
						? "noIncidence"
						: undefined,
			},
		});
	};

	return (
		<Card style={{ marginBottom: 16 }}>
			<LoadVolunteerModal
				isVisible={showAddModal}
				coorporate={props.coorporate}
				onClose={() => setShowAddModal(false)}
				onFinish={() => {
					setShowAddModal(false);
					setCoorporateProfileFunction({
						launch: true,
						function: "volunteer",
					});
				}}
			/>
			<TitleHeader>
				<Text type="p" weight={"semibold"}>
					Voluntarios
					<Text type="c1" style={{ color: Color.text.high }}>
						{props.userCount} resultados
					</Text>
				</Text>
				<Row>
					<Filter
						id="filter-incidence"
						placeholder="Incidencia"
						type={"single"}
						options={[
							{
								id: "noIncidence",
								label: "Sin incidencia",
							},
							{
								id: "incidence",
								label: "Con incidencia",
							},
						]}
						selectedOptions={selectedOption}
						onChange={onFilterChange}
					/>
					<Button
						style={{ right: 16, top: 12 }}
						design={"image"}
						icon={
							<Plus
								height={20}
								width={20}
								color={Color.text.primary}
							/>
						}
						onClick={() => setShowAddModal(true)}
					/>
				</Row>
			</TitleHeader>
			{props.userArray.length > 0 ? (
				<CardList
					data={props.userArray.map((item) => ({
						user: item,
						labels: [
							item.incidence ? "Incidencia" : undefined,
							item.state,
						],
					}))}
					type="volunteer"
				/>
			) : (
				<Text type="p2" style={{ color: Color.text.high }}>
					No hay voluntarios
				</Text>
			)}
		</Card>
	);
};

export default SectionVolunteer;
export interface Props {
	coorporate: CoorporateScheme;
	userCount: number;
	userArray: Array<any>;
	onCoorporateChanged: (coorporate: CoorporateScheme) => void;
}
