import React, { useState } from "react";
import { Incidence, UserScheme } from "client-v2";

import { Modal, Input, TextArea } from "@adoptaunabuelo/react-components";

const IncidenceModal = (props: Props) => {
	const [selection, setSelection] = useState<{
		name?: string;
		desc?: string;
	}>({
		name: undefined,
		desc: undefined,
	});
	const [error, setError] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);

	const onSave = () => {
		if (selection.name && selection.desc) {
			setError(undefined);
			setLoading(true);
			Incidence.create({
				userId: props.currentUser.objectId,
				...selection,
			})
				.then(() => {
					setLoading(false);
					props.onClose();
				})
				.catch((e: string) => {
					setLoading(false);
					setError(e);
				});
		} else {
			setError(
				"Debes completar todos los datos para enviar la incidencia"
			);
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			type="form"
			title="Crear incidencia"
			subtitle="Añade toda la información posible que permita identificar el error"
			onClose={props.onClose}
			contentStyle={{ marginTop: 12 }}
			error={error}
			buttonProps={{
				children: "Enviar",
				loading: loading,
				onClick: onSave,
			}}
			options={[
				{
					id: "name",
					title: "Título",
					Data: (
						<Input
							type="text"
							placeholder="Descripción breve"
							onChange={(e) =>
								setSelection({
									...selection,
									name: e.target.value,
								})
							}
						/>
					),
				},
				{
					id: "desc",
					title: "Descripción",
					Data: (
						<TextArea
							type="default"
							placeholder="Describe en detalle la incidencia"
							onChange={(e) =>
								setSelection({
									...selection,
									desc: e.target.value,
								})
							}
						/>
					),
				},
			]}
		/>
	);
};
export default IncidenceModal;
export interface Props {
	isVisible: boolean;
	currentUser: UserScheme;
	onClose: () => void;
}
