import React, { ChangeEvent, useState, useEffect } from "react";
import {
	User,
	Residence,
	UserScheme,
	ResidenceScheme,
	ErrorScheme,
} from "client-v2";
import { Input, Modal } from "@adoptaunabuelo/react-components";

const EmergencyContactModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();
	const [form, setForm] = useState<{
		id: string | undefined;
		name: string | undefined;
		surname: string | undefined;
		email: string | undefined;
		phone: string | undefined;
		countryCode: string | undefined;
		position: string | undefined;
		comment: string | undefined;
	}>({
		id: undefined,
		name: undefined,
		surname: undefined,
		phone: undefined,
		countryCode: undefined,
		email: undefined,
		position: undefined,
		comment: undefined,
	});
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.contact) {
			let temp: any = {
				id: props.contact.id,
				name: props.contact.name,
				surname: props.contact.surname,
				email: props.contact.email,
				position: props.contact.position,
				comment: props.contact.comment,
			};
			if (props.contact.phone) {
				const result = phoneUtil.parseAndKeepRawInput(
					props.contact.phone
				);
				if (result && result.values_) {
					const values = result.values_;
					temp = {
						...temp,
						phone: values[2].toString(),
						countryCode: "+" + values[1],
					};
					setForm(temp);
				}
			} else {
				setForm(temp);
			}
		} else if (props.residence) {
			const lastContact = props.residence.contacts
				? props.residence.contacts[props.residence.contacts.length-1]
				: undefined;
			setForm({
				...form,
				id: lastContact
					? (parseInt(lastContact.id) + 1).toString()
					: "0",
			});
		}
	}, [props.contact, props.residence]);

	const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError("");
		const text = e.target.value;
		setForm({
			...form,
			name: text,
		});
	};

	const onPhoneChange = (data: {
		country: string;
		value: string;
		isValid: boolean;
	}) => {
		setError("");
		if (data.isValid) {
			setForm({
				...form,
				phone: data.value,
				countryCode: data.country,
			});
		} else {
			setForm({
				...form,
				phone: undefined,
				countryCode: undefined,
			});
		}
	};

	const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError("");
		const text = e.target.value;
		setForm({
			...form,
			email: text,
		});
	};

	const onSurnameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError("");
		const text = e.target.value;
		setForm({
			...form,
			surname: text,
		});
	};

	const onCargoChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError("");
		setForm({
			...form,
			position: e.target.value,
		});
	};

	const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
		setError("");
		setForm({
			...form,
			comment: e.target.value,
		});
	};

	const onSave = async () => {
		setLoading(true);
		if (form.name) {
			if (props.user) {
				User.set(props.user.objectId, {
					data: {
						emergencyContact: {
							...form,
							relationship: form.position,
							phone:
								form.countryCode && form.phone
									? form.countryCode + form.phone
									: undefined,
						},
					},
				})
					.then((user) => {
						setLoading(false);
						props.onSave(user.data);
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
						setError(error.message);
					});
			} else if (props.residence) {
				const array = props.residence.contacts
					? props.residence.contacts.filter(
							(item) => item.id !== form.id
					  )
					: [];
				const tempArray = [
					...array,
					{
						...form,
						phone:
							form.countryCode && form.phone
								? form.countryCode + form.phone
								: props.contact?.phone
								? props.contact.phone
								: undefined,
						countryCode: undefined,
					},
				];
				Residence.set(props.residence.objectId, {
					contacts: tempArray,
				})
					.then((residence) => {
						props.onSave(undefined, residence.data);
					})
					.catch((error: ErrorScheme) => {
						setError(error.message);
					});
			}
			setForm({
				id: undefined,
				name: undefined,
				surname: undefined,
				phone: undefined,
				countryCode: undefined,
				email: undefined,
				position: undefined,
				comment: undefined,
			});
		} else setError("Debes añadir nombre para avanzar");
		setLoading(false);
	};

	const onClose = () => {
		setError("");
		setForm({
			id: undefined,
			name: undefined,
			surname: undefined,
			phone: undefined,
			countryCode: undefined,
			email: undefined,
			position: undefined,
			comment: undefined,
		});
		props.onClose();
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			title={
				props.user ? "Editar contacto emergencia" : "Editar contacto"
			}
			onClose={onClose}
			error={error}
			options={
				props.user
					? [
							{
								id: "name",
								title: "Nombre",
								Data: (
									<Input
										type="text"
										name="name"
										placeholder="Nombre"
										defaultValue={form.name}
										onChange={onNameChange}
									/>
								),
							},
							{
								id: "phone",
								title: "Teléfono",
								Data: (
									<Input
										type={"tel"}
										name="phone"
										placeholder="Nº de teléfono"
										defaultValue={form.phone}
										country={form.countryCode}
										onPhoneChange={onPhoneChange}
									/>
								),
							},
							{
								id: "position",
								title: "Parentesco",
								Data: (
									<Input
										type="text"
										placeholder="Parentesco"
										defaultValue={form.position}
										onChange={onCargoChange}
									/>
								),
							},
					  ]
					: props.residence
					? [
							{
								id: "name",
								title: "Nombre",
								Data: (
									<Input
										type="text"
										name="name"
										style={{ flex: 1 }}
										placeholder="Nombre"
										defaultValue={
											props.residence && props.contact
												? props.contact.name
												: undefined
										}
										onChange={onNameChange}
									/>
								),
							},
							{
								id: "surname",
								title: "Apellidos",
								Data: (
									<Input
										type="text"
										placeholder="Apellidos"
										defaultValue={
											props.residence && props.contact
												? props.contact.surname
												: undefined
										}
										onChange={onSurnameChange}
									/>
								),
							},
							{
								id: "phone",
								title: "Teléfono",
								Data: (
									<Input
										type={"tel"}
										name="phone"
										defaultValue={form.phone}
										country={form.countryCode}
										placeholder="Nº de teléfono"
										onPhoneChange={onPhoneChange}
									/>
								),
							},
							{
								id: "email",
								title: "Email",
								Data: (
									<Input
										type={"email"}
										placeholder="Email"
										defaultValue={
											props.residence && props.contact
												? props.contact.email
												: undefined
										}
										onChange={onEmailChange}
									/>
								),
							},
							{
								id: "position",
								title: "Cargo",
								Data: (
									<Input
										type="text"
										placeholder="Cargo"
										defaultValue={
											props.residence && props.contact
												? props.contact.position
												: undefined
										}
										onChange={onCargoChange}
									/>
								),
							},
							{
								id: "comment",
								title: "Comentario",
								Data: (
									<Input
										type="text"
										placeholder="Comentario"
										defaultValue={
											props.residence && props.contact
												? props.contact.comment
												: undefined
										}
										onChange={onCommentChange}
									/>
								),
							},
					  ]
					: []
			}
			buttonProps={{
				children: "Guardar",
				onClick: onSave,
				disabled: !form.name,
				loading: loading,
			}}
		/>
	);
};
export default EmergencyContactModal;
export interface Props {
	isVisible: boolean;
	user?: UserScheme;
	residence?: ResidenceScheme;
	contact?: any;
	onSave: (user?: UserScheme, residence?: ResidenceScheme) => void;
	onClose: () => void;
}
