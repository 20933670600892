const getBaseUrl = (): string => {
	return process.env.NODE_ENV === "production"
		? "https://sqe78ajvu1g4l9vzisq7gst60ulsui.files-sashido.cloud"
		: "https://zmadwoa9sdn103iq5yeq37v173sz60.files-sashido.cloud";
};

export function changeImageSource(source: string | undefined): string {
	if (!source) return "";
	const sourceParts = source.split("/");
	const fileName = sourceParts.pop();

	return `${getBaseUrl()}/${fileName}`;
}
