import React, { useState } from "react";
import styled from "styled-components";
import { Event, Product, EventScheme, ProductScheme } from "client-v2";

import {
	Input,
	Color,
	Modal,
	TextArea,
} from "@adoptaunabuelo/react-components";
import { changeImageSource } from "../../../utils/changeImageSource";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 18px;
	margin-bottom: 18px;
`;
const Image = styled.img`
	display: flex;
	flex: 1;
	height: 200px;
	object-fit: cover;
	border-radius: 12px;
	background-color: ${Color.background.soft};
`;

const InfoModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [selection, setSelection] = useState({
		title: props.product.title,
		description: props.event.description,
		price: props.product.price,
		limit: props.product.limit,
		image: changeImageSource(props.event.image ? props.event.image.url : undefined),
		changeImage: false,
	});
	const [error, setError] = useState<string | undefined>(undefined);

	const onSave = async () => {
		console.log(selection);
		if (
			selection.title &&
			selection.description &&
			selection.price >= 0 &&
			selection.limit &&
			selection.image
		) {
			setLoading(true);
			const promise = await Promise.all([
				Product.set(props.product.objectId, {
					title: selection.title,
					price: selection.price,
					limit: selection.limit,
				}),
				Event.set(props.event.objectId, {
					description: selection.description,
					image: changeImageSource(selection.changeImage ? selection.image : undefined),
				}),
			]);
			setLoading(false);
			props.onSave &&
				props.onSave({
					product: promise[0].data,
					event: promise[1].data,
				});
		} else {
			setError("Debes completar todos los datos para continuar.");
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={props.onClose}
			title={"Editar información"}
			error={error}
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
		>
			<Row
				style={{
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Image src={selection.image} />
				<Input
					type="image"
					maxHeight={1000}
					maxWidth={1000}
					label={props.event.image ? "Cambiar foto" : "Añadir foto"}
					onChange={(file: string) =>
						setSelection({
							...selection,
							image: file,
							changeImage: true,
						})
					}
				/>
			</Row>
			<Row>
				<Input
					containerStyle={{ flex: 1 }}
					type="text"
					design={"secondary"}
					placeholder="Título"
					defaultValue={props.product.title}
					value={selection.title}
					onChange={(e) =>
						setSelection({
							...selection,
							title: e.target.value,
						})
					}
				/>
			</Row>
			<Row>
				<Input
					containerStyle={{ flex: 1 }}
					type="number"
					design={"secondary"}
					placeholder="Precio"
					defaultValue={props.product.price}
					value={selection.price}
					onChange={(e) =>
						setSelection({
							...selection,
							price: parseInt(e.target.value),
						})
					}
				/>
				<Input
					containerStyle={{ flex: 1 }}
					type="number"
					design={"secondary"}
					placeholder="Plazas disponibles"
					defaultValue={props.product.limit}
					value={selection.limit}
					onChange={(e) =>
						setSelection({
							...selection,
							limit: parseInt(e.target.value),
						})
					}
				/>
			</Row>
			<TextArea
				type="default"
				style={{ height: 250, marginBottom: 32 }}
				placeholder="Descripción"
				design={"secondary"}
				defaultValue={props.event.description}
				onChange={(e) =>
					setSelection({
						...selection,
						description: e.target.value,
					})
				}
			/>
		</Modal>
	);
};
export default InfoModal;
export interface Props {
	isVisible: boolean;
	event: EventScheme;
	product: ProductScheme;
	onClose: () => void;
	onSave?: (result: { product: ProductScheme; event: EventScheme }) => void;
}
