import React, { useState } from "react";
import styled from "styled-components";
import { UserScheme, Challenge, ChallengeScheme } from "client-v2";
import moment from "moment";
import { json2csv } from "json-2-csv";

import Card from "../../../components/Containers/Card";
import CardList from "../../../components/Profile/CardList";
import { Text, Button } from "@adoptaunabuelo/react-components";
import { Download } from "lucide-react";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const UsersSection = (props: Props) => {
	const [loading, setLoading] = useState(false);

	const downloadCSV = () => {
		setLoading(loading);
		//Get the data
		Challenge.setEmailList(props.challenge.objectId)
			.then((result) => {
				//Generate CSV
				const csv = json2csv(result.data, {
					emptyFieldValue: "",
				});

				//Download file
				downloadFile(csv, "colaboradores.csv");
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
			});
	};

	const downloadFile = (data: string, fileName: string) => {
		//Download file
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<Card>
			<TitleHeader>
				<Text type="p" weight={"semibold"}>
					{props.users.length} colaboradores
				</Text>
				<Button
					size={"small"}
					design="call-to-action"
					loading={loading}
					onClick={downloadCSV}
					icon={<Download height={18} width={18} />}
				>
					Descargar
				</Button>
			</TitleHeader>
			<CardList
				data={props.users.map((item) => ({
					user: item.user,
					labels: [
						item.amount ? item.amount + "€" : undefined,
						moment(item.createdAt).format("DD/MM/YYYY"),
					],
				}))}
			/>
		</Card>
	);
};
export default UsersSection;
export interface Props {
	challenge: ChallengeScheme;
	users: Array<{
		user: UserScheme;
		createdAt: Date;
		amount?: number;
		letter?: boolean;
	}>;
}
