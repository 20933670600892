import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { AUA, User, Auth, UserScheme } from 'client-v2';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State} from '../redux';
import { bindActionCreators } from 'redux';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import AuthLayout from './Auth';
import Volunteer from './Volunteer';
import Grandpa from './Grandpa';
import Task from './Task';
import Residence from './Residence';
import Coorporate from './Coorporate';
import Event from './Event';
import Challenge from './Challenge';
import Sales from './Sales';
import Ambassador from './Ambassador';
import Push from "./Push";
import SideBar from '../components/Nav/SideBar';
import { Feedback, ColorV2 } from '@adoptaunabuelo/react-components';

const Container = styled.div`
	display: flex;
	flex: 1;
	height: 100vh;
`;
const ContainerProfile = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: ${ColorV2.surface.background};
	width: -webkit-fill-available;
	overflow: auto;
`;

const App = (props: Props) =>{

    const dispatch = useDispatch();
    const taskTableFilters = useSelector((state:State)=>state.taskTableFilters);
	const { setTaskTableFilters, setCurrentUser, setError } = bindActionCreators(actionsCreators, dispatch);
    const error: {isVisible: boolean, text: string} = useSelector((state:State)=>state.modal ? state.modal.error : undefined);
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(true);
    const [ user, setUser ] = useState<UserScheme | undefined>(undefined);
    const [ stripePromise, setStripePromise ] = useState<Promise<Stripe | null> | undefined>(undefined);

    useEffect(() =>{
        initServer()
    },[]);

    const initServer = async () =>{
        // const env: any = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
        // const localhost = process.env.REACT_APP_LOCALHOST ? true : false;
        const env: any = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
        const localhost = process.env.REACT_APP_LOCALHOST ? true : false;

        //Init Parse Server conection
        await AUA.initServer(env, localhost, undefined, true);

        const stripeKey = AUA.getStripeKey();
        const tempStripePromise = loadStripe(stripeKey);
        setStripePromise(tempStripePromise);

        User.currentAsync().then((result) =>{
            const user = result.data;
            if(user){
                setUser(user);
                const type = user.type;
                if(type !== 'admin'){
                    Auth.logout();
                    setLoading(false);
                    navigate('/auth', {
                        replace: true
                    });
                }
                else{
                    const active = user.active;
                    if(!active){
                        Auth.logout();
                        setLoading(false);
                        navigate('/auth', {
                            replace: true
                        });
                    }
                    else{
                        //Init task filters
                        const department = user.data ? user.data.department : undefined;
                        setTaskTableFilters({
                            ...taskTableFilters,
                            priority: department === 'sales' ? ['high', 'medium', 'low'] : undefined,
							state: department === 'sales' ? ['pending'] : ['pending', 'inProgress'],
                            adminId: department === 'admin' ? undefined : user.objectId,
                            group: department === 'admin' ? 'support' : department
                        });
                        setCurrentUser(user)
                        setLoading(false);
                    }
                }
            }
            else{
                navigate('/auth', {
                    replace: true
                });
                setLoading(false);
            }
        }).catch((e: string) =>{
            setUser(undefined);
            navigate('/auth', {
                replace: true
            });
            setLoading(false);
        });
    }

    return((loading || !stripePromise) ? null :
        <Elements stripe={stripePromise}>
            <Container>
                <SideBar/>
                <ContainerProfile>
                    {error &&
                        <Feedback
                            isVisible={error.isVisible}
                            type={'error'}
                            text={error.text}
                            onClose={() => setError(false, '')}
                        />
                    }
                    <Routes>
                        <Route path="/auth" element={<AuthLayout/>}/>
                        <Route path="/voluntarios/*" element={<Volunteer/>}/>
                        <Route path="/abuelos/*" element={<Grandpa/>}/>
                        <Route path="/tareas/*" element={<Task/>}/>
                        <Route path="/residencias/*" element={<Residence/>}/>
                        <Route path="/corporativo/*" element={<Coorporate/>}/>
                        <Route path="/evento/*" element={<Event/>}/>
                        <Route path="/reto/*" element={<Challenge/>}/>
                        <Route path="/ventas/*" element={<Sales/>}/>
                        <Route path="/embajadores/*" element={<Ambassador/>}/>
                        <Route path="/push/*" element={<Push/>}/>
                        <Route path="/" element={<Navigate to={user ? "/tareas" : "/auth"} replace/>} />
                    </Routes>
                </ContainerProfile>
            </Container>  
        </Elements>
    )
}
export default App;
export interface Props{

}