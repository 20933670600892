import React, { useEffect, useState } from "react";
import { ErrorScheme, Match, MatchScheme, UserScheme } from "client-v2";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";

import Card from "../../../components/Containers/Card";
import {
	Text,
	Button,
	Color,
	Modal,
	RadioButton,
} from "@adoptaunabuelo/react-components";
import { Plus } from "lucide-react";
import CardList from "../../../components/Profile/CardList";
import MatchModal from "../../../components/Modals/MatchModal";
import AddVisitModal from "../../../components/Modals/AddVisitModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const ShowMore = styled.div`
	width: 100%;
	display: flex;
	margin-top: 8px;
	cursor: pointer;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SectionMatch = (props: Props) => {
	const dispatch = useDispatch();
	const role = useSelector((state: State) => state.role);
	const { setProfileFunction } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const [matches, setMatches] = useState<
		{ user: UserScheme; match: MatchScheme }[]
	>([]);
	const [modalData, setModalData] = useState<any>({
		show: false,
		match: undefined,
		error: "",
		action: "",
		title: "",
		subtitle: "",
	});
	const [showMatchModal, setShowMatchModal] = useState(false);
	const [showAddVisitModal, setShowAddVisitModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showHidden, setShowHidden] = useState(false);
	const [selectedMotivation, setSelectedMotivation] = useState<
		string | undefined
	>(undefined);

	useEffect(() => {
		const temp = props.matchArray.map((match) => {
			const user =
				props.user.type === "volunteer"
					? match.Grandpa
					: match.Volunteer;
			return {
				user: user,
				match: match,
			};
		});
		setMatches(temp);
	}, [props.matchArray]);

	const resetModalData = () => {
		setModalData({
			show: false,
			match: undefined,
			error: "",
			onSave: undefined,
			title: "",
			subtitle: "",
		});
	};

	const cancelMatch = async () => {
		if (selectedMotivation) {
			setLoading(true);
			Match.remove(modalData.match.objectId, {
				motivation: selectedMotivation,
				adminId: props.admin.objectId,
			})
				.then((result) => {
					setProfileFunction({ launch: true, function: "match" });
					resetModalData();
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setModalData({
						...modalData,
						error: error.message,
					});
					setLoading(false);
				});
		}
	};

	const reactiveMatch = async () => {
		setLoading(true);
		Match.create({
			volunteerId: modalData.match.Volunteer.objectId,
			grandpaId: modalData.match.Grandpa.objectId,
			adminId: props.admin.objectId,
		})
			.then((result) => {
				setProfileFunction({ launch: true, function: "old-match" });
				resetModalData();
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setModalData({
					...modalData,
					error: error.message,
				});
				setLoading(false);
			});
	};

	const setMatchTop = async () => {
		setLoading(true);
		Match.set(modalData.match.objectId, {
			top: modalData.match.top ? false : true,
		})
			.then(() => {
				setProfileFunction({ launch: true, function: "old-match" });
				resetModalData();
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setModalData({
					...modalData,
					error: error.message,
				});
			});
	};

	const onSaveModal = async () => {
		switch (modalData.action) {
			case "cancel-match":
				await cancelMatch();
				break;
			case "reactive-match":
				await reactiveMatch();
				break;
			case "set-top":
				await setMatchTop();
				break;
		}
	};

	const onMatchSave = () => {
		setShowMatchModal(false);
		setProfileFunction({ launch: true, function: "match" });
		setProfileFunction({ launch: true, function: "appointment" });
	};

	const showOldMatches = async () => {
		if (showHidden) {
			setProfileFunction({ launch: true, function: "match" });
			setShowHidden(false);
		} else {
			setProfileFunction({ launch: true, function: "old-match" });
			setShowHidden(true);
		}
	};

	return (
		<>
			<AddVisitModal
				isVisible={showAddVisitModal}
				volunteer={props.user.type === "volunteer" ? props.user : modalData.user}
				grandpa={props.user.type === "grandpa" ? props.user : modalData.user}
				onClose={() => setShowAddVisitModal(false)}
			/>
			<Modal
				isVisible={modalData.show}
				title={modalData.title}
				subtitle={modalData.subtitle}
				error={modalData.error}
				onClose={() => {
					setModalData({ ...modalData, show: false });
					setSelectedMotivation(undefined);
				}}
				buttonProps={{
					loading: loading,
					children: modalData.buttonLabel,
					disabled:
						modalData.action === "cancel-match"
							? selectedMotivation
								? false
								: true
							: false,
					onClick: onSaveModal,
				}}
			>
				{modalData.action === "cancel-match" ? (
					<RadioButton
						type={"single"}
						options={
							props.user.Coorporate
								? [
										{
											id: "no-working",
											children: (
												<Text type="p">
													Ya no trabaja en la empresa
												</Text>
											),
										},
										{
											id: "corporate-renovation",
											children: (
												<Text type="p">
													No renovación de la empresa
												</Text>
											),
										},
										{
											id: "grandpa",
											children: (
												<Text type="p">
													El abuelo se dio de baja
												</Text>
											),
										},
										{
											id: "experience",
											children: (
												<Text type="p">
													La experiencia con su abuelo
													no ha sido buena
												</Text>
											),
										},
										{
											id: "app",
											children: (
												<Text type="p">
													La app no funciona bien
												</Text>
											),
										},
										{
											id: "other-association",
											children: (
												<Text type="p">
													Va a hacer voluntariado con
													otra entidad
												</Text>
											),
										},
										{
											id: "personal",
											children: (
												<Text type="p">
													Motivos personales
												</Text>
											),
										},
										{
											id: "no-answer",
											children: (
												<Text type="p">
													No constesta al equipo
												</Text>
											),
										},
										{
											id: "no-answer-residence",
											children: (
												<Text type="p">
													La residencia no contesta
												</Text>
											),
										},
										{
											id: "commitment",
											children: (
												<Text type="p">Falta de compromiso</Text>
											),
										},
								  ]
								: [
										{
											id: "grandpa",
											children: (
												<Text type="p">
													El abuelo se dio de baja
												</Text>
											),
										},
										{
											id: "experience",
											children: (
												<Text type="p">
													La experiencia con su abuelo
													no ha sido buena
												</Text>
											),
										},
										{
											id: "app",
											children: (
												<Text type="p">
													La app no funciona bien
												</Text>
											),
										},
										{
											id: "other-association",
											children: (
												<Text type="p">
													Va a hacer voluntariado con
													otra entidad
												</Text>
											),
										},
										{
											id: "personal",
											children: (
												<Text type="p">
													Motivos personales
												</Text>
											),
										},
										{
											id: "no-answer",
											children: (
												<Text type="p">
													No constesta al equipo
												</Text>
											),
										},
										{
											id: "no-answer-residence",
											children: (
												<Text type="p">
													La residencia no contesta
												</Text>
											),
										},
										{
											id: "commitment",
											children: (
												<Text type="p">Falta de compromiso</Text>
											),
										},
								  ]
						}
						onChange={(op) => setSelectedMotivation(op[0].id)}
					/>
				) : null}
			</Modal>
			<MatchModal
				isVisible={showMatchModal}
				onClose={() => setShowMatchModal(false)}
				user={props.user}
				onSave={onMatchSave}
			/>
			<Card style={{ position: "relative", marginBottom: 16 }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						{props.user.type === "grandpa"
							? "Voluntarios"
							: "Abuelos"}
					</Text>
					{role.user.set.match && (
						<Button
							design={"image"}
							icon={
								<Plus
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							}
							disabled={
								props.user.type === "volunteer"
									? props.user.state === "match" ||
									  props.user.state === "waiting" ||
									  props.user.state === "exVolunteer"
										? false
										: true
									: false
							}
							style={{ position: "absolute", right: 16, top: 12 }}
							onClick={() => setShowMatchModal(true)}
						/>
					)}
				</TitleHeader>
				{matches.length > 0 ? (
					<CardList
						data={matches}
						hideMenu={!role.user.set.match}
						onSave={(data) => {
							if (data.action === "add-visit"){
								setShowAddVisitModal(true);
								setModalData({ ...data, show: false });
							}
							else setModalData({ ...data, show: true });
						}}
					/>
				) : (
					<Text type="p2" style={{ color: Color.text.medium }}>
						{props.user.type === "grandpa"
							? "Sin voluntarios"
							: "Sin abuelos"}
					</Text>
				)}
				<ShowMore onClick={showOldMatches}>
					<Text type="p" style={{ textDecoration: "underline" }}>
						{showHidden ? "ocultar inactivos" : "ver todos"}
					</Text>
				</ShowMore>
			</Card>
		</>
	);
};

export default SectionMatch;
export interface Props {
	user: UserScheme;
	admin: UserScheme;
	matchArray: MatchScheme[];
}
