import React, { useEffect, useState } from "react";
import { Constant, ErrorScheme, User, UserScheme } from 'client-v2';

import { Modal, Dropdown } from '@adoptaunabuelo/react-components';

const PreferencesModal = (props: Props) =>{

    const hobbies = Constant.hobbies().map(i => ({ id:i.id, title: i.title }));
    const schedule = props.user.data ? props.user.data.schedule : undefined;
    const [ loading, setLoading ] = useState(false);
    const [ days ] = useState(Constant.schedule().days); 
    const [ hours ] = useState(Constant.schedule().hours); 
    const [ selectedHours, setSelectedHours ] = useState<Array<any>>((schedule && schedule.hours) ? schedule.hours.map((item: string) => ({id: item})) : []);
    const [ selectedDays, setSelectedDays ] = useState<Array<any>>((schedule && schedule.dates) ? schedule.dates.map((item: string) => ({id: item})) : []);
    const [ selectedHobbies, setSelectedHobbies ] = useState<Array<any>>(Constant.hobbies().map((it:any,i:number)=>{
        const result = props?.user?.data?.hobbies?.some((e:any)=>e===it.id)
        if(result)
            return {
                id:it.id, 
                title:it.title
            }
    }).filter((n: any) => n)) 
    const [ selectedSlots, setSelectedSlots ] = useState<string>(props.user.internalData && props.user.internalData.slot ? props.user.internalData.slot.toString() : '4');
    const [ error, setError ] = useState<string>("");
    const [ options, setOptions ] = useState<Array<any>>([])

    useEffect(() =>{
        const options = [
            {
                id: 'hobbies',
                title: 'Aficiones',
                Data: <Dropdown 
                    id='hobbies-dropdown'
                    menuStyle={{maxHeight: 150}}
                    placeholder={'Aficiones'}
                    onChange={(array:Array<any>)=>{setSelectedHobbies(array)}} 
                    type={"multiple"} 
                    options={hobbies}
                    selectedOptions={selectedHobbies}
                />
            },
            {
                id: 'separator'
            },
            {
                id: 'schedule',
                title: 'Disponibilidad',
                Data: <div>
                    <Dropdown
                        id='days-dropdown'
                        type={"multiple"} 
                        style={{marginBottom: 8}}
                        menuStyle={{maxHeight: 100}}
                        placeholder="Dias disponibles"
                        options={days}
                        selectedOptions={selectedDays}
                        onChange={onDaysChange} 
                    />
                    <Dropdown
                        id='hours-dropdown'
                        type={"multiple"} 
                        placeholder="Horas disponibles"
                        options={hours}
                        menuPosition='top'
                        selectedOptions={selectedHours}
                        onChange={onHoursChange} 
                    />
                </div>
            }
        ]
        if(props.user.type === 'grandpa'){
            const temp = [
                {
                    id: 'slots',
                    title: 'Nº de voluntarios',
                    Data: <Dropdown 
                        id='slots-dropdown'
                        menuStyle={{maxHeight: 150}}
                        placeholder={'Nº de voluntarios'}
                        onChange={(array:Array<any>)=>{setSelectedSlots(array[0].title)}} 
                        type={"single"} 
                        options={[
                            {
                                id: 'option-1',
                                title: '1'
                            },
                            {
                                id: 'option-2',
                                title: '2'
                            },
                            {
                                id: 'option-3',
                                title: '3'
                            },
                            {
                                id: 'option-4',
                                title: '4'
                            }
                        ]}
                        selectedOptions={selectedSlots ? [{id: 'option-'+selectedSlots}] : []}
                    />
                },
                {
                    id: 'separator'
                }
            ].concat(options)
            setOptions(temp);
        }
        else setOptions(options);
    },[]);

    const onSave = async () => {
        setLoading(true);
        User.set(props.user.objectId, {
            data: {
                hobbies: selectedHobbies.map((item:any)=>item.id),
                schedule: selectedDays && selectedHours ? {
                    hours: selectedHours.map((item:any)=>item.id),
                    dates: selectedDays.map((item:any)=>item.id)
                } : undefined,
            },
            internalData: {
                slot: selectedSlots ? parseInt(selectedSlots) : undefined 
            }
        }).then((user) =>{
            setLoading(false);
            props.onSave(user.data);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        });
    }

    const onDaysChange = (array: Array<any>) =>{
        setSelectedDays(array);
    }

    const onHoursChange = (array: Array<any>) =>{
        setSelectedHours(array);
    }

	return (
		<Modal
            type='form'
            isVisible={props.isVisible}
            title={"Preferencias"}
            options={options}
            onClose={props.onClose} 
            error={error}
            buttonProps={{
                onClick: onSave,
                loading: loading
            }}
        />
	);
}
export default PreferencesModal;
export interface Props{
    isVisible: boolean,
    user: UserScheme,
    onSave: (user: UserScheme) => void,
    onClose: () => void
}
