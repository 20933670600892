import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Challenge, Constant, ErrorScheme, Product, Push } from "client-v2";
import Fuse from "fuse.js";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../redux";
import { bindActionCreators } from "redux";

import {
	Input,
	TextArea,
	Dropdown,
	ColorV2,
	Text,
} from "@adoptaunabuelo/react-components";
import RegisterForm from "../../components/Form/RegisterForm";
import {
	BadgeEuro,
	Heart,
	Mail,
	Cake,
	PencilLine,
	CalendarDays,
} from "lucide-react";

const Container = styled.div`
	position: relative;
	display: flex;
	width: -webkit-fill-available;
	height: 100vh;
	flex-direction: column;
	padding: 16px 32px 32px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const { setError } = bindActionCreators(actionsCreators, dispatch);

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [fuse, setFuse] = useState<any>(undefined);
	const [countryArray, setCountryArray] = useState<Array<any>>([]);
	const [idArray, setIdArray] = useState<
		Array<{ id: string; title: string }>
	>([]);
	const [form, setForm] = useState<any>({
		title: undefined,
		subtitle: undefined,
		country: undefined,
		os: undefined,
		link: undefined,
		id: undefined,
	});

	useEffect(() => {
		const temp = Constant.country();
		setFuse(
			new Fuse(temp, {
				keys: ["title", "id"],
			})
		);
		setCountryArray(temp);
	}, []);

	const onSearchChange = (searchText: string) => {
		if (searchText) {
			const result = fuse.search(searchText);
			setCountryArray(result.map((i: any) => i.item));
		} else {
			setCountryArray(Constant.country());
		}
	};

	const onLinkChange = (item: string) => {
		if (item === "evento") {
			setLoading(true);
			Product.get({
				type: "event",
				startDate: {
					min: new Date(),
				},
			})
				.then(async (result) => {
					const result2 = result.data.map((product) => ({
						id: product.objectId,
						title: product.title,
					}));
					setIdArray(result2);
					setForm({
						...form,
						link: item,
						id: result2[0].id,
					});
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
				});
		} else if (
			item === "challenge" ||
			item === "birthday" ||
			item === "signature"
		) {
			setLoading(true);
			Challenge.get({
				endDate: {
					min: new Date(),
				},
				type: item === "challenge" ? "dream" : item,
			})
				.then((result) => {
					const result2 = result.data.map((challenge) => ({
						id: challenge.objectId,
						title: challenge.title,
					}));
					setIdArray(result2);
					setForm({
						...form,
						link: item,
						id: result2[0].id,
					});
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
				});
		} else {
			setForm({
				...form,
				link: item,
				id: undefined,
			});
		}
	};

	const onSendClick = () => {
		if (form.title && form.subtitle) {
			setLoading(true);
			Push.send({
				filter:
					form.os || form.country
						? {
								deviceType: form.os,
								localeIdentifier: form.country,
						  }
						: undefined,
				notification: {
					title: form.title,
					body: form.subtitle,
				},
				data:
					form.link || form.id
						? {
								path:
									"aua://" +
									form.link +
									(form.id ? "/" + form.id : ""),
						  }
						: undefined,
			})
				.then(() => {
					setLoading(false);
					setSuccess(true);
				})
				.catch((e: string) => {
					setError(true, e);
					setLoading(false);
				});
		} else {
			setError(true, "Debes completar Título y Contenido para continuar");
		}
	};

	return (
		<Container>
			<Text
				type="h1"
				style={{ fontWeight: 600, fontSize: 32, marginBottom: 16 }}
			>
				Enviar push
			</Text>
			<RegisterForm
				buttonProps={{
					children: "Enviar push",
					loading: loading,
					success: success,
					onClick: onSendClick,
					onSuccess: () => setSuccess(false),
				}}
				options={[
					{
						id: "separator",
						title: "Selecciona la audiencia",
					},
					{
						id: "so",
						title: "S.O.",
						Data: (
							<Dropdown
								id={"dropdown-so"}
								type="single"
								placeholder="Sistema Operativo"
								options={[
									{
										id: "ios",
										title: "iOS",
									},
									{
										id: "android",
										title: "Android",
									},
								]}
								onChange={(i) =>
									setForm({
										...form,
										os: i[0].id,
									})
								}
							/>
						),
					},
					{
						id: "country",
						title: "País",
						Data: (
							<Dropdown
								id={"dropdown-country"}
								type="single"
								placeholder="País"
								options={countryArray}
								onSearchChange={onSearchChange}
								onChange={(i) =>
									setForm({
										...form,
										country: i[0].id,
									})
								}
							/>
						),
					},
					{
						id: "separator",
						title: "Escribe tu mensaje",
					},
					{
						id: "title",
						title: "Título",
						Data: (
							<Input
								type="text"
								placeholder="Título"
								onChange={(e) => {
									setForm({
										...form,
										title: e.target.value,
									});
								}}
							/>
						),
					},
					{
						id: "subtitle",
						title: "Contenido",
						Data: (
							<TextArea
								type="default"
								placeholder="Escribe el contenido del mensaje"
								onChange={(e) => {
									setForm({
										...form,
										subtitle: e.target.value,
									});
								}}
							/>
						),
					},
					{
						id: "link",
						title: "Link interno",
						Data: (
							<Row>
								<Dropdown
									id="dropdown-link"
									placeholder="Categoría"
									type="single"
									options={[
										{
											id: "donacion",
											title: "Proceso de donación",
											icon: (
												<BadgeEuro
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
										{
											id: "letter",
											title: "Campaña de cartas",
											icon: (
												<Mail
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
										{
											id: "evento",
											title: "Evento",
											icon: (
												<CalendarDays
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
										{
											id: "challenge",
											title: "Sueño",
											icon: (
												<Heart
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
										{
											id: "birthday",
											title: "Cumpleaños",
											icon: (
												<Cake
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
										{
											id: "signature",
											title: "Campaña de firmas",
											icon: (
												<PencilLine
													height={20}
													width={20}
													color={
														ColorV2.text.neutralHard
													}
												/>
											),
										},
									]}
									onChange={(e) => {
										onLinkChange(e[0].id);
									}}
								/>
								{(form.link === "evento" ||
									form.link === "challenge" ||
									form.link === "birthday" ||
									form.link === "signature") && (
									<Dropdown
										id={"dropdown-id"}
										placeholder="Puntero"
										type="single"
										options={idArray}
										selectedOptions={idArray.filter(
											(i) => i.id === form.id
										)}
										menuStyle={{
											maxWidth: "unset",
											width: "max-content",
											right: 0,
											left: "unset",
										}}
										onChange={(i) =>
											setForm({
												...form,
												id: i[0].id,
											})
										}
									/>
								)}
							</Row>
						),
					},
				]}
			/>
		</Container>
	);
};
export default Table;
export interface Props {}
