import React, { useState } from "react";
import styled from "styled-components";
import { Challenge, ChallengeScheme } from "client-v2";
import moment from "moment";

import { Modal, Input, TextArea } from "@adoptaunabuelo/react-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const TimelineModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [selection, setSelection] = useState<{
		title?: string;
		subtitle?: string;
		date?: string;
		time?: string;
	}>({
		title: undefined,
		subtitle: undefined,
		date: undefined,
		time: undefined,
	});

	const onSave = () => {
		if (
			selection.title &&
			selection.subtitle &&
			selection.date &&
			selection.time
		) {
			setLoading(true);
			const date = moment(
				selection.date + " " + selection.time,
				"DD/MM/YYYY HH:mm"
			).toDate();
			const temp = props.challenge.timeline;
			temp.push({
				title: selection.title,
				subtitle: selection.subtitle,
				createdAt: date.toDateString(),
			});
			Challenge.set(props.challenge.objectId, {
				timeline: temp,
			})
				.then((challenge) => {
					setLoading(false);
					props.onSave && props.onSave(challenge.data);
				})
				.catch((e: string) => {
					setLoading(false);
					setError(e);
				});
		} else {
			setError("Debes completar todos los datos antes de continuar");
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			type="form"
			title="Añadir actualización"
			onClose={props.onClose}
			buttonProps={{
				loading: loading,
				onClick: onSave,
			}}
			options={[
				{
					id: "title",
					title: "Título",
					Data: (
						<Input
							type="text"
							placeholder="Título"
							onChange={(e) =>
								setSelection({
									...selection,
									title: e.target.value,
								})
							}
						/>
					),
				},
				{
					id: "subtitle",
					title: "Subtítulo",
					Data: (
						<TextArea
							type="default"
							placeholder="Subtítulo"
							onChange={(e) =>
								setSelection({
									...selection,
									subtitle: e.target.value,
								})
							}
						/>
					),
				},
				{
					id: "date",
					title: "Fecha",
					Data: (
						<Row>
							<Input
								containerStyle={{ display: "flex", flex: 1 }}
								type="date"
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) =>
									setSelection({
										...selection,
										date: e.target.value,
									})
								}
							/>
							<Input
								type="time"
								onChange={(e) =>
									setSelection({
										...selection,
										time: e.target.value,
									})
								}
							/>
						</Row>
					),
				},
			]}
		></Modal>
	);
};
export default TimelineModal;
export interface Props {
	isVisible: boolean;
	challenge: ChallengeScheme;
	onClose: () => void;
	onSave?: (challenge: ChallengeScheme) => void;
}
