import React, { useState } from "react";
import { ErrorScheme, User, UserScheme } from 'client-v2';

import { Modal, TextArea } from '@adoptaunabuelo/react-components';

const HistoryModal = (props: Props) =>{

    const [ history, setHistory ] = useState(props.user.live?.history);
    const [ error, setError ] = useState("")
    const [ loading, setLoading ] = useState(false);

    const onHistoryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>{
        const { value } = e.target
        setHistory(value);
    }

    const onSave = async () => {
        setLoading(true);
        User.set(props.user.objectId, {
            live: {
                history: history
            }
        }).then((user) =>{
            props.onSave(user.data)
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        })
    }
	return (
		<Modal
            isVisible={props.isVisible}
            title={"Historia de vida"} 
            onClose={props.onClose} 
            error={error}
            buttonProps={{
                onClick: onSave,
                loading: loading
            }}
        >
            <TextArea 
                type="default"
                style={{height:300, marginBottom: 24}} 
                name="history" 
                onChange={onHistoryChange} 
                defaultValue={history}
            />
        </Modal>
	);
}
export default HistoryModal;
export interface Props{
    isVisible: boolean,
    user: UserScheme,
    onClose: () => void,
    onSave: (user: UserScheme) => void
}
