import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';
import { Device, Call as CallTwilio } from '@twilio/voice-sdk';
import Draggable from 'react-draggable';
import { AdminTask, Call, User, AdminTaskScheme, UserScheme, ErrorScheme } from 'client-v2';

import { Color, Text, Avatar } from '@adoptaunabuelo/react-components';
import { Phone, MicOff, Mic, Keyboard, PauseCircle, Circle } from 'lucide-react';
import PhoneHangupIcon from '../../assets/img/phone-hangup.svg'
import Steps from './Steps';
import { changeImageSource } from '../../utils/changeImageSource';

const modalWidth = 320;
const modalHeight = 180;
const modalStepsHeight = 340;
const Background = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color:rgba(0, 0, 0, 0.75);
    z-index: 900;
`
const Container = styled.div<{hide: boolean}>`
    position: absolute;
    display: ${props => props.hide ? 'none' : 'flex'};
    flex-direction: column;
    width: ${modalWidth+'px'};
    background-color: ${Color.background.deepBlue};
    z-index: 300000;
    border-radius: 12px;
    padding: 16px;
    cursor: all-scroll;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
`
const ModalContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: ${modalWidth+'px'};
    background-color: white;
    z-index: 300000;
    border-radius: 12px;
    padding: 16px 16px 0px;
    cursor: all-scroll;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: ${modalStepsHeight+'px'};
`
const DataView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const BottomView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 8px;
`
const CallButton = styled.button`
    height: 40px;
    width: 64px;
    border-radius: 600px;
    border: none;
    background-color: ${Color.status.color.error};
    cursor: pointer;
    &:hover{
        background-color: #FF5A5A;
    }
`
const SquareButton = styled.button<{off: boolean}>`
    height: 40px;
    width: 40px;
    border-radius: 600px;
    border: none;
    cursor: pointer;
    background-color: ${props => props.off ? Color.text.whiteHigh : '#FFFFFF1F'};
    &:hover{
        background-color: ${props => props.off ? 'white' : '#FFFFFF52'};
    }
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const UserNameTitle = styled(Text)<{clickable: boolean}>`
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
    &:hover{
        text-decoration: ${props => props.clickable ? 'underline' : 'none'};
    }
`
const KeyboardView = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px 0px;
`
const KeyboardKey = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    width: 52px;
    border-radius: 40px;
    margin: 4px 6px;
    background-color: ${Color.text.whiteHigh};
    cursor: pointer;
    :hover{
        background-color: white;
    }
`

const PhoneModal = (props: Props) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
	const { setCallUser, setAutodial } = bindActionCreators(actionsCreators, dispatch);
    const callUser: UserScheme = useSelector((state:State)=>state.call ? state.call.user : undefined);
    const callAdminTask: AdminTaskScheme = useSelector((state:State)=>state.call ? state.call.adminTask : undefined);
    const autodial: {play: boolean, list: Array<{user: UserScheme, adminTask: AdminTaskScheme}>} = useSelector((state:State)=> state.autodial);
    const currentUser: UserScheme = useSelector((state:State)=>state.currentUser);

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const topLeftPosition = {x: 245, y: 8};
    const topRightPosition = {x: windowWidth-245, y: 8};
    const topLeftPositionStep = {x: 32, y: 32};
    const topRightPositionStep = {x: windowWidth-modalStepsHeight-32-32, y: 32};
    const bottomLeftPositionStep = {x: 32, y: windowHeight-modalStepsHeight-28-24};
    const bottomRightPositionStep = {x: windowWidth-modalWidth-32-32, y: windowHeight-modalStepsHeight-28-24};
    const timer = useRef<number>();

    const [ micOff, setMicOff ] = useState(false);
    const [ keyboardOff, setKeyboardOff ] = useState(false);
    const [ recordingOff, setRecordingOff ] = useState(false);
    const [ device, setDevice ] = useState<Device | undefined>(undefined);
    const [ call, setCall ] = useState<CallTwilio | undefined>(undefined);
    const [ seconds, setSeconds ] =  useState(0);
    const [ bottomLeftPosition,  setBottomLeftPosition] = useState({x: 245, y: windowHeight-modalHeight});
    const [ bottomRightPosition, setBottomRightPosition ] = useState({x: windowWidth-245, y: windowHeight-modalHeight});
    const [ modalPosition, setModalPosition ] = useState(bottomRightPosition);
    const [ modalPositionStep, setModalPositionStep ] = useState(bottomRightPositionStep);
    const [ user, setUser ] = useState<UserScheme | any>(callUser);
    const [ adminTask, setAdminTask ] = useState<AdminTaskScheme | undefined>(callAdminTask);
    const [ nextCall, setNextCall ] = useState<{user: UserScheme, adminTask: AdminTaskScheme} | undefined>(undefined)
    const [ isIncoming, setIsIncoming ] = useState(false);
    const [ showSteps, setShowSteps ] = useState(false);

    useEffect(() =>{
        return ()=> {
            finishCall();
        }
    },[]);

    useEffect(()=>{
        if(currentUser && !device)
            initTwilio(currentUser);
    },[currentUser])

    useEffect(() =>{
        if(callUser){
            setUser(callUser);
            setAdminTask(callAdminTask);
            initCall(callUser);
        }
    }, [callUser]);

    useEffect(() =>{
        if(autodial.play){  //Init calls
            const currentCall = autodial.list[0];
            const nextCall = autodial.list[1];
            setNextCall(nextCall);
            navigate('/tareas/'+currentCall.adminTask.objectId);
            setCallUser(currentCall.user, {
                adminTask: currentCall.adminTask
            });
            if(currentCall.adminTask.state === 'pending'){
                AdminTask.set(currentCall.adminTask.objectId, {
                    state: 'inProgress',
                    adminId: currentUser.objectId
                });
            }
        }
        else {  //Stop calling
            setUser(undefined);
            setAdminTask(undefined);
            setCallUser(null);
        }
    }, [autodial.play]);

    const initTwilio = (currentUser: UserScheme) =>{
        Call.getToken(currentUser.objectId).then((result) =>{
            const device = new Device(result.data, {
                appName: 'Adopta Un Abuelo',
                allowIncomingWhileBusy: false,
                closeProtection: 'Hay una llamada en proceso. Si cierras o recargas la página, la llamada se finalizará.'
            });
            device.register();
            device.on("registered", () => {
                console.log('READY');
            });
            device.on('error', (error) =>{
                console.log('ERROR', error);
            });
            device.on("incoming", (call: CallTwilio) => {
                console.log('INCOMING');
                setIsIncoming(true);
                setCall(call);
                getCaller(call.parameters.From);
                
                call.on('accept', () => {
                    initTimer();
                    props.onStateChange('absent');
                });
                call.on('cancel', () =>{
                    finishCall();
                });
                call.on('disconnect', () =>{
                    finishCall();
                });
                call.on('reject', () =>{
                    finishCall();
                });
                call.on('error', (error) =>{
                    console.error('ERROR', error);
                    finishCall();
                });
            });
            setDevice(device);
        }).catch((error: ErrorScheme) =>{
            console.error(error.message);
        });
    }

    const initTimer = () =>{
        timer.current = window.setInterval(() => {
            setSeconds((seconds) => seconds+1);
        }, 1000);
    }

    const getCaller = async (phone: string | undefined) =>{
        if(phone){
            User.get({
                phone: phone
            }).then((result) =>{
                setUser(result.data[0]);
            }).catch((error: ErrorScheme) =>{
                //Create empty user
                setUser({
                    objectId: 'unknown',
                    name: 'Desconocido',
                    surname: '('+phone+')',
                    phone: phone
                })
            });
        }
    }

    const initCall = (user: UserScheme) => {
		try{
            if(device){
                const group = (currentUser.data && currentUser.data.department) ? currentUser.data.department : 'none';
                device.connect(callAdminTask ? {
                    params:{
                        To: user.phone, 
                        callerUser: currentUser.objectId,
                        receiverUser: user.objectId, 
                        app:"dashboard",
                        group: group,
                        adminTaskId: callAdminTask.objectId
                    }
                } : { 
                    params:{
                        To: user.phone,
                        callerUser: currentUser.objectId,
                        receiverUser: user.objectId, 
                        app:"dashboard",
                        group: group
                    }
                }).then((call) =>{
                    const isMuted = call.isMuted();
                    setMicOff(isMuted);
                    setIsIncoming(false);

                    call.on('accept', (call) => {
                        //Init recording if is a sales call
                        setCall(call);
                        if(currentUser && currentUser.data && currentUser.data.department === 'sales'){
                            onRecordClick(call);
                        }
                        initTimer();
                        props.onStateChange('absent');
                    });
                    call.on('cancel', () =>{
                        finishCall();
                    });
                    call.on('disconnect', () =>{
                        finishCall();
                    });
                    call.on('reject', () =>{
                        finishCall();
                    });
                    call.on('error', (error) =>{
                        console.error('ERROR', error);
                        finishCall();
                    });
                });
            }
		}
		catch(e){
			console.error(e)
		}
	};

    const finishCall = () =>{
        setCall(undefined);
        setCallUser(null);
        props.onStateChange('available');
        clearInterval(timer.current);
        setSeconds(0);
        if(autodial.play){
            //Open modal
            setShowSteps(true);
            if(modalPosition.x <= windowWidth/2 && modalPosition.y > windowHeight/2){
                setModalPosition({x: 232, y: windowHeight-394});
            }
            else if (modalPosition.x > windowWidth/2 && modalPosition.y > windowHeight/2){
                setModalPosition({x: windowWidth-230, y: windowHeight-394});
            }
        }
    }

    const acceptCall = () =>{
        if(call){
            call.accept();
        }
    }

    const rejectCall = () =>{
        setCallUser(null);
        if(call){
            call.reject();
        }
    }

    const endCall = () => {
        finishCall();
        if(device){
		    device.disconnectAll();
        }
	};

    const changeMic = () =>{
        if(call){
            call.mute(!micOff);
            setMicOff(!micOff);
        }
    }

    const changeKeyboard = () => {
        if(keyboardOff){
            //Close
            const bottomLeft = {...bottomLeftPosition, y: windowHeight-modalHeight};
            const bottomRight = {...bottomRightPosition, y: windowHeight-modalHeight};
            setBottomLeftPosition(bottomLeft);
            setBottomRightPosition(bottomRight);
            if(modalPosition.x <= windowWidth/2 && modalPosition.y <= windowHeight/2){
                setModalPosition(bottomLeft);
            }
            else if (modalPosition.x > windowWidth/2 && modalPosition.y <= windowHeight/2){
                setModalPosition(bottomRight);
            }
        }
        else{
            //Open
            const bottomLeft = {...bottomLeftPosition, y: windowHeight-modalHeight-288};
            const bottomRight = {...bottomRightPosition, y: windowHeight-modalHeight-288};
            setBottomLeftPosition(bottomLeft);
            setBottomRightPosition(bottomRight);
            if(modalPosition.x <= windowWidth/2 && modalPosition.y > windowHeight/2){
                setModalPosition(bottomLeft);
            }
            else if (modalPosition.x > windowWidth/2 && modalPosition.y > windowHeight/2){
                setModalPosition(bottomRight);
            }
        }
        setKeyboardOff(!keyboardOff);
    }

    const onKeyboardClick = (index: number) =>{
        call?.sendDigits(index.toString());
    }

    const onRecordClick = (call?: CallTwilio) =>{
        if(call && call.parameters && call.parameters.CallSid){
            Call.record({
                callId: call.parameters.CallSid,
                status: recordingOff ? 'pause' : 'init'
            }).then(() =>{
                setRecordingOff(!recordingOff);
            }).catch((error: ErrorScheme) =>{
                console.error(error);
            })
        }
    }

    const onModalDragStop = (e: any) =>{
        if(e.x <= windowWidth/2){
            if(e.y <= windowHeight/2){
                setModalPosition(topLeftPosition);
                setModalPositionStep(topLeftPositionStep);
            }
            else{
                setModalPositionStep(bottomLeftPositionStep);
                setModalPosition(bottomLeftPosition);
            }
        }
        else{
            if(e.y <= windowHeight/2){
                setModalPosition(topRightPosition);
                setModalPositionStep(topRightPositionStep);
            }
            else{
                setModalPositionStep(bottomRightPositionStep);
                setModalPosition(bottomRightPosition);
            }
        }
    }

    const onStepSave = (option: string) =>{
        //close steps
        setShowSteps(false);
        if(modalPosition.x <= windowWidth/2 && modalPosition.y > windowHeight/2){
            setModalPosition(bottomLeftPosition);
        }
        else if (modalPosition.x > windowWidth/2 && modalPosition.y > windowHeight/2){
            setModalPosition(bottomRightPosition);
        }

        if(option === 'next'){
            goToNextCall();
        }
        else if(option === 'pause'){
            setUser(undefined);
            setAdminTask(undefined);
            setAutodial(false, []);
        }
        else if(option === 'repeat'){
            setCallUser(autodial.list[0].user, {
                adminTask: autodial.list[0].adminTask
            });
        }
    }

    const goToNextCall = () =>{
        //remove first position of the array
        if(autodial.list.length > 0){
            autodial.list.splice(0, 1);

            //Navigate to the next task
            navigate('/tareas/'+autodial.list[0].adminTask.objectId);

            //set the new callUser
            setCallUser(autodial.list[0].user, {
                adminTask: autodial.list[0].adminTask
            });
            
            //set the new nextCall
            setNextCall(autodial.list.length > 1 ? autodial.list[1] : undefined);
        }
        else
            setAutodial(false, []);
    }

    const renderKeyboardKey = (index: number) =>(
        <KeyboardKey
            onClick={() => onKeyboardClick(index)}
        >
            <Text type='h4'>
                {index}
            </Text>
        </KeyboardKey>
    )

    return(
        <>
        {(autodial.play && process.env.NODE_ENV === 'development') && false &&
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: 400,
                    width: 300,
                    backgroundColor: 'red',
                    zIndex: 1000000
                }}
            >
                {autodial.list.map((item, index) =>(
                    <div
                        key={'test-cell-'+index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 4,
                            borderBottom: '1px solid',
                            backgroundColor: (user && adminTask) ? (item.user.objectId === user.objectId && item.adminTask.objectId === adminTask.objectId) ? 'green' : (nextCall ? (nextCall.user.objectId === item.user.objectId && nextCall.adminTask.objectId === item.adminTask.objectId ? 'yellow' : 'transparent') : 'transparent') : 'transparent'
                        }}
                    >
                        <Text type='p'>
                            {item.adminTask.objectId}
                        </Text>
                        <Text type='p'>
                            {item.user.name}
                        </Text>
                    </div>
                ))}
            </div>
        }
        {(showSteps && adminTask) ?
            <Background>
                <Draggable
                    bounds='body'
                    position={modalPositionStep}
                    onStop={onModalDragStop}
                >
                    <ModalContainer>
                        <Steps
                            admin={currentUser}
                            currentCall={{
                                user: user,
                                adminTask: adminTask
                            }}
                            nextCall={nextCall}
                            onSave={onStepSave}
                        />
                    </ModalContainer>
                </Draggable>
            </Background>
        :
            <Draggable
                bounds='body'
                position={modalPosition}
                onStop={onModalDragStop}
            >
                <Container 
                    hide={callUser ? false : true}
                >
                    <DataView>
                        <Avatar
                            style={{height: 48, width: 48}}
                            name={callUser ? callUser.name : undefined}
                            icon={changeImageSource(callUser ? callUser.image?.url : undefined)}
                        />
                        <Column style={{marginLeft: 12}}>
                            <UserNameTitle 
                                type='p' 
                                weight={'medium'} 
                                clickable={(callUser && callUser.objectId !== 'unknown') ? true : false} 
                                style={{color: 'white'}}
                                onClick={() =>(callUser && callUser.objectId !== 'unknown') ? (callUser?.type === 'volunteer' ? navigate('/voluntarios/perfil?id='+callUser?.objectId) : navigate('/abuelos/perfil?id='+callUser?.objectId)) : undefined}
                            >
                                {callUser ? callUser.name+' '+callUser.surname : ''}
                            </UserNameTitle>
                            {!isIncoming &&
                                <Text type='p2' style={{color: 'white'}}>
                                    {seconds > 0 ? ("0"+Math.floor(seconds / 60)).slice(-2)+":"+("0"+(seconds-(Math.floor(seconds / 60))*60)).slice(-2) : 'Llamando...'}
                                </Text>
                            }
                        </Column>
                    </DataView>
                    {keyboardOff &&
                        <KeyboardView>
                            <Row>
                                {renderKeyboardKey(1)}
                                {renderKeyboardKey(2)}
                                {renderKeyboardKey(3)}
                            </Row>
                            <Row>
                                {renderKeyboardKey(4)}
                                {renderKeyboardKey(5)}
                                {renderKeyboardKey(6)}
                            </Row>
                            <Row>
                                {renderKeyboardKey(7)}
                                {renderKeyboardKey(8)}
                                {renderKeyboardKey(9)}
                            </Row>
                            <Row>
                                {renderKeyboardKey(0)}
                            </Row>
                        </KeyboardView>
                    }
                    {isIncoming ?
                        <BottomView>
                            <CallButton
                                onClick={rejectCall}
                            >
                                <img src={PhoneHangupIcon} style={{height: 25, width: 25}}/>
                            </CallButton>
                            <CallButton
                                style={{marginLeft: 8, backgroundColor: Color.status.color.success}}
                                onClick={acceptCall}
                            >
                                <Phone color='white' width={20} height={20}/>
                            </CallButton>
                        </BottomView>
                    :
                        <BottomView>
                            <SquareButton 
                                off={recordingOff} 
                                onClick={() => onRecordClick(call)}
                            >
                                {recordingOff ?
                                    <PauseCircle color={'white'} fill={Color.status.color.error} width={24} height={24}/>
                                :
                                    <Circle fill={Color.status.color.error} color={Color.status.color.error} width={20} height={20}/>
                                }
                            </SquareButton>
                            <SquareButton 
                                off={keyboardOff} 
                                onClick={changeKeyboard}
                            >
                                <Keyboard color={keyboardOff ? Color.text.primary : 'white'} width={20} height={20}/>
                            </SquareButton>
                            <SquareButton 
                                off={micOff} 
                                onClick={changeMic}
                            >
                                {micOff ?
                                    <MicOff color={micOff ? Color.text.primary : 'white'} width={20} height={20}/>
                                :
                                    <Mic color={'white'} width={20} height={20}/>
                                }
                            </SquareButton>
                            <CallButton
                                onClick={endCall}
                            >
                                <img src={PhoneHangupIcon} style={{height: 25, width: 25}}/>
                            </CallButton>
                        </BottomView>
                    }
                </Container>
            </Draggable>
        }
        </>
    )
}
export default PhoneModal;
export interface Props{
    onStateChange: (state: string) => void
}