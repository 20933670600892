import React, { useEffect, useState } from "react";
import {
	Constant,
	User,
	CoorporateScheme,
	ResidenceScheme,
	UserScheme,
	ErrorScheme,
} from "client-v2";
import styled from "styled-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import Card from "../../../components/Containers/Card";
import {
	Color,
	Button,
	Text,
	Modal,
	Menu,
	Label,
	Avatar,
} from "@adoptaunabuelo/react-components";
import { School, Edit2 } from "lucide-react";
import EditUserModal from "../../../components/Modals/PersonalModal";
import { changeImageSource } from "../../../utils/changeImageSource";

const TitleHeader = styled.div`
	display: flex;
	margin-bottom: 13px;
	justify-content: space-between;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Logo = styled.img`
	height: 40px;
	width: 40px;
	background-color: ${Color.background.soft};
	border-radius: 6px;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

const SectionMain = (props: Props) => {
	const dispatch = useDispatch();
	const role = useSelector((state: State) => state.role);
	const { setProfileFunction, setError } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [topOptions, setTopOptions] = useState<Array<any>>([]);
	const [stateOption, setStateOption] = useState<string | undefined>(
		undefined
	);
	const [stateOptions, setStateOptions] = useState([
		{
			id: "banned",
			label: "Bloquear",
		},
	]);
	const grandpa = props.user.type === "grandpa" ? true : false;

	useEffect(() => {
		generateStateOptions();
		if (props.user.type === "grandpa") {
			generateTopData(props.user);
		}
	}, []);

	const generateStateOptions = () => {
		const type = props.user.type;
		const state = props.user.state;
		if (role.user.set.state) {
			const result = Constant.state();
			const result2 = result
				.filter((item: any) =>
					type === "volunteer" && item.volunteer
						? true
						: type === "grandpa" && item.grandpa
						? true
						: false
				)
				.map((item: any) => ({
					id: item.id,
					label: item.title,
				}));
			setStateOptions(result2);
		} else {
			if (type === "volunteer" && state === "waiting") {
				setStateOptions([
					...stateOptions,
					{
						id: "exVolunteer",
						label: "Cancelar pendiente de match",
					},
				]);
			}
		}
	};

	const generateTopData = (user: any) => {
		if (user.internalData) {
			const resultOptions: any = [
				{
					id: "consulting",
					label: "Consultorio",
					defaultSelection:
						user.internalData && user.internalData.consulting,
				},
				{
					id: "influencer",
					label: "Influencer",
					defaultSelection:
						user.internalData && user.internalData.influencer,
				},
				{
					id: "press",
					label: "Prensa",
					defaultSelection:
						user.internalData && user.internalData.press,
				},
				{
					id: "recipes",
					label: "Recetas",
					defaultSelection:
						user.internalData && user.internalData.recipes,
				},
				{
					id: "showcooking",
					label: "Showcooking",
					defaultSelection:
						user.internalData && user.internalData.showcooking,
				},
				{
					id: "tiktok",
					label: "Tiktok",
					defaultSelection:
						user.internalData && user.internalData.tiktok,
				},
				{
					id: "sketches",
					label: "Sketches",
					defaultSelection:
						user.internalData && user.internalData.sketches,
				},
				{
					id: "blog",
					label: "Blog",
					defaultSelection:
						user.internalData && user.internalData.blog,
				},
			];
			setTopOptions(resultOptions);
		}
	};

	const changeState = () => {
		setLoading(true);
		User.set(props.user.objectId, {
			state: stateOption,
		})
			.then((result) => {
				setStateOption(undefined);
				props.onUserChanged(result.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setError(true, error.message);
			});
	};

	return (
		<>
			<EditUserModal
				isVisible={showEditModal}
				user={props.user}
				corporate={props.corporate}
				residence={props.residence}
				onClose={() => setShowEditModal(false)}
				onSave={(user, corporate, residence) => {
					setShowEditModal(false);
					if (corporate) {
						setProfileFunction({
							launch: true,
							function: "corporate",
							data: {
								corporate: corporate,
							},
						});
					} else if (residence) {
						setProfileFunction({
							launch: true,
							function: "residence",
							data: {
								residence: residence,
							},
						});
					}
					props.onUserChanged(user);
				}}
			/>
			<Modal
				isVisible={stateOption ? true : false}
				title="¿Seguro que quieres continuar?"
				subtitle={
					stateOption === "banned"
						? "Con esta acción el usuario quedará bloqueado y no podrá participar en el voluntariado"
						: stateOption === "exVolunteer"
						? "Se cancelará la petición de match de este usuario"
						: "¡Atención! Ten cuidado y asegúrate de que has seleccionado el estado correcto. Modificar el estado de un usuario puede provocar fallos en la app del usuario si no seleccionas el correcto."
				}
				buttonProps={{
					children: "Aceptar",
					loading: loading,
					onClick: changeState,
				}}
				onClose={() => setStateOption(undefined)}
			/>
			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Avatar
						style={{ height: 80, width: 80, fontSize: 32 }}
						name={props.user.name}
						icon={changeImageSource(props.user.image && props.user.image.url)}
						clickable={props.user.image ? true : false}
					/>
					<Button
						design="image"
						style={{ position: "absolute", right: 16, top: 12 }}
						icon={<Edit2 height={20} width={20} />}
						onClick={() => setShowEditModal(true)}
					/>
				</TitleHeader>
				<Text type="h5" weight={"semibold"}>
					{props.user.name} {props.user.surname}
				</Text>
				{props.user.birthday ? (
					<Text type="p2" style={{ marginTop: 4 }}>
						{moment().diff(props.user.birthday.iso, "years")} años,{" "}
						{moment(props.user.birthday.iso).format("DD MMMM YYYY")}
					</Text>
				) : (
					<Text
						type="p"
						style={{
							marginTop: 4,
							width: "fit-content",
							backgroundColor: grandpa
								? Color.status.color.warning
								: "transparent",
						}}
					>
						Sin cumpleaños
					</Text>
				)}
				<Row style={{ marginTop: 8, marginBottom: 8 }}>
					<Menu
						id={"state-menu"}
						position={"bottom-right"}
						menuStyle={{ top: 32, width: 300 }}
						Icon={<Label text={props.user.state} />}
						options={stateOptions}
						onClick={(option) => setStateOption(option.id)}
					/>
					{props.user.type === "ambassador" && (
						<Label
							style={{ marginLeft: 8 }}
							backgroundColor={Color.status.color.warning}
							text={"Embajador"}
						/>
					)}
					{props.user.internalData?.notValidLead && (
						<Label
							style={{ marginLeft: 8 }}
							color={Color.status.color.error}
							backgroundColor={Color.status.color.errorDefault}
							text={"Bloqueo llamada comercial"}
						/>
					)}
					{props.user.internalData?.lopdUrl && (
						<Label
							style={{ marginLeft: 8 }}
							color={Color.status.color.success}
							backgroundColor={Color.status.color.successDefault}
							text={"✅ LOPD"}
						/>
					)}
				</Row>
				{props.corporate && (
					<Row
						style={{
							alignItems: "center",
							marginTop: 18,
							cursor: "pointer",
							gap: 12,
						}}
						onClick={() => {
							if (props.corporate)
								navigate(
									"/corporativo/perfil/?id=" +
										props.corporate.objectId
								);
						}}
					>
						<Logo src={props.corporate.logo?.url} />
						<Col>
							<Text type="c1" style={{ color: Color.text.high }}>
								Empresa
							</Text>
							<Text type="p">{props.corporate.name}</Text>
						</Col>
					</Row>
				)}
				{props.residence && (
					<Row
						style={{
							alignItems: "center",
							marginTop: 18,
							cursor: "pointer",
							gap: 12,
						}}
						onClick={() => {
							if (props.residence)
								navigate(
									"/residencias/perfil/?id=" +
										props.residence.objectId
								);
						}}
					>
						<School
							height={28}
							width={28}
							color={Color.text.high}
						/>
						<Col>
							<Text type="c1" style={{ color: Color.text.high }}>
								Residencia
							</Text>
							<Text type="p">{props.residence.name}</Text>
						</Col>
					</Row>
				)}
				<Row
					style={{
						alignItems: "center",
						justifyContent: "space-between",
						marginTop: 8,
					}}
				>
					<Text type="c1" style={{ color: Color.text.high }}>
						Registrado el{" "}
						{moment(props.user.createdAt).format("DD/MM/YYYY")}
					</Text>
				</Row>
			</Card>
		</>
	);
};

export default SectionMain;
export interface Props {
	user: UserScheme;
	residence?: ResidenceScheme;
	admin?: UserScheme;
	corporate?: CoorporateScheme;
	onUserChanged: (user: UserScheme) => void;
}
