import React, { useState, useEffect, useCallback } from 'react';
import { UserScheme } from 'client-v2';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';

import { Avatar, Color, Text, Button } from '@adoptaunabuelo/react-components';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete, InfoWindow } from "@react-google-maps/api";
import { changeImageSource } from '../../utils/changeImageSource';

const Input = styled.input`
	position: absolute;
	left: 16px;
	top: 16px;
	box-sizing: border-box;
	border: 1px solid transparent;
	width: 320px;
	height: 40px;
	padding: 0 16px;
	border-radius: 60px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	font-size: 14px;
	outline: none;
	text-overflow: ellipses;
	font-family: 'Poppins';
	color: ${Color.text.full};
`
const InfoWindowView = styled.div`
	display: flex;
	cursor: pointer;
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 8px;
`

const Map = (props: Props) =>{

	const [ map, setMap ] = useState<any | undefined>(undefined);
	const [ initialLocation, setInitialLocation ] = useState({
		lat: 40.416650,
		lng: -3.703784
	});
	const [ initialZoom, setInitialZoom ] = useState(6);
	const [ autocomplete, setAutocomplete ] = useState<any>(undefined);
	const [ selectedIndex, setSelectedIndex ] = useState<number | undefined>(undefined);
	const [ radius, setRadius ] = useState(10);
	const [ canResearch, setCanResearch ] = useState(false);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk"
	})

	useEffect(() =>{
		if(props.city){
			getCoordinates(props.city);
		}
	},[props.city]);

	useEffect(() =>{
		const radius = calculateRadius(initialZoom);
		setRadius(radius);
	},[initialZoom]);

	const getCoordinates = async (address: string) => {
		const result = await geocodeByAddress(address);
		const latLng = await getLatLng(result[0]);
		setInitialLocation(latLng);
		setInitialZoom(11);
	}

	const onMapLoad = useCallback((map: any) => {
		setMap(map);
	  }, []);

	const onAutocompleteLoad = (autocomplete: any) =>{
		setAutocomplete(autocomplete);
	}
	
	const onPlaceChanged = () => {
		if(autocomplete) {
			const result = autocomplete.getPlace();
			const location = {
				lat: result.geometry.location.lat(),
				lng: result.geometry.location.lng()
			}
			setInitialLocation(location);
			setInitialZoom(11);
			props.onLocationChange && props.onLocationChange({
				...location,
				km: 10
			});
		} 
		else
			console.log('Autocomplete is not loaded yet!')
	}

	const research = () =>{
		if(map){
			const zoom = map.zoom;
			setInitialZoom(zoom);
			setInitialLocation(map.center);
			props.onLocationChange && props.onLocationChange({
				lat: map.center.lat(),
				lng: map.center.lng(),
				km: radius
			});
		}
		setCanResearch(false);
	}

	const calculateRadius = (zoom: number) =>{
		return zoom === 6 ? 600 
			: zoom === 7 ? 300 
			: zoom === 8 ? 150 
			: zoom === 9 ? 75 
			: zoom === 10 ? 37 
			: zoom === 11 ? 18
			: zoom === 12 ? 9
			: zoom === 13 ? 4.5
			: zoom === 13 ? 2.25
			: 1.125;
	}

	return(isLoaded ?
		<GoogleMap
			mapContainerStyle={{
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
				...props.style
			}}
			center={initialLocation}
			zoom={initialZoom}
			clickableIcons={false}
			options={{
				disableDefaultUI: true
			}}
			onLoad={onMapLoad}
			onCenterChanged={() => setCanResearch(true)}
			onZoomChanged={() => setCanResearch(true)}
		>
			<Autocomplete
				onLoad={onAutocompleteLoad}
				onPlaceChanged={onPlaceChanged}
			>
				<Input
					type="text"
					placeholder="Buscar dirección"
				/>
			</Autocomplete>
			{canResearch &&
				<Button
					style={{position: 'absolute', left: 16, top: 64}}
					size='small'
					onClick={research}
				>
					Buscar en esta zona
				</Button>
			}
			{props.userArray.map((item, index) =>{
				const isSelected = selectedIndex === index;
				if(item.location){
					return(
						<div key={'marker'+item.objectId}>
							<Marker
								position={{
									lat: item.location.latitude,
									lng: item.location.longitude
								}}
								icon={{url: require('../../assets/img/pin.png')}}
								onClick={() => setSelectedIndex(index)}
							/>
							{isSelected &&
								<InfoWindow
									position={{
										lat: item.location.latitude,
										lng: item.location.longitude
									}}
									onCloseClick={() => setSelectedIndex(undefined)}
								>
									<InfoWindowView
										onClick={() => props.onRowClick && props.onRowClick(item)}
									>
										<Avatar
											name={item.name}
											icon={changeImageSource(item.image?.url)}
											style={{height: 36, width: 36}}
										/>
										<Column>
											<Text type='p' style={{fontSize: 12}}>
												{item.name} {item.surname}
											</Text>
											<Text type='p' style={{fontSize: 12, color: Color.text.high}}>
												{item.Residence ? 'Residencia' :  item.Coorporate ? 'Empresa' : (item.type === 'volunteer' ? 'App' : 'Domicilio')}
											</Text>
										</Column>
									</InfoWindowView>
								</InfoWindow>
							}
						</div>
					)
				}
			})}
		</GoogleMap>
	: null)
}
export default Map;
export interface Props{
	style?: any,
	userArray: Array<UserScheme>,
	city?: string,
	onLocationChange?: (location: {lat: number, lng: number, km: number}) => void,
	onRowClick?: (row: UserScheme) => void
}